const projectsData = [
  {
    // company: "This resume",
    logo: "stephane",
    // spanTime: "2018",
    jobTitle: "This resume",
    inCharge:
      "Made with ReactJS. Versioned via GitHub. Hosted on Netlify. Main librairies used : React-Router, SemanticUI, Emotion (for Css in JS), Stylus and linkify"
  },
  {
    // company: "O'Clock",
    logo: "felikitty",
    // spanTime: "2018",
    jobTitle: "Felikitty Android App",
    inCharge:
      "Know more about cats. This Android App is a progressive web app, embeded in a android wrapper. Main librairies used : Redux, Redux-thunk, Bulma, styled-componenets, Axios, React-Lazy-image. Play Store Link: https://bit.ly/2HEMPms"
  },
  {
    // company: "O'Clock",
    logo: "myinnerweather",
    // spanTime: "2018",
    jobTitle: "myinnerweather.com",
    inCharge:
      "Let you enter your mood to check your feeling across days. Made with ReactJS and Firebase. Main librairies used : React-vis, Redux, Redux-form, Bulma, Moment. Link: www.myinnerweather.com"
  },
  {
    // company: "O'Clock",
    logo: "maltaweather",
    // spanTime: "2018",
    jobTitle: "malta-weather.net",
    inCharge:
      "Give current and forcecast weather via retrieving datas from an API. Made with ReactJS and NodeJS. Versioned. Main librairies used : express, node-fetch, Axios, Lodash and Moment. Link: malta-weather.net"
  },
  {
    // company: "ISCOM Paris",
    logo: "whoiswhois",
    // spanTime: "1999",
    jobTitle: "whois-whois.net",
    inCharge:
      "Checking if a domaine name is available via an API. Made with NodeJS & ReactJS. Versioned. Main librairies used : Helmet, Cors, Express, Express-rate-limit, Axios, SemanticUI : www.whois-whois.net"
  },
  {
    // company: "http://mobile-labs.eu/html/index.html",
    logo: "memorygame",
    // spanTime: "1997",
    jobTitle: "Memory Game",
    inCharge:
      "Beat the machine with this memory game made with JQuery. http://mobile-labs.eu/html/index.html"
  }
]

export default projectsData
