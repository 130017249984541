import baobaz from "./baobaz.png"
import datasolution from "./datasolution.png"
import cnav from "./cnav.png"
import malakoff from "./malakoff.png"
import odyssee from "./odyssee.png"
import assurOne from "./assurOne.png"
import mtx from "./mtx.jpg"
//school
import oclock from "./oclock.jpg"
import opquast from "./opquast.png"
import iscom from "./iscom.jpg"
//projects
import stephane from "./project/stephane.lanteri.fr.png"
import maltaweather from "./project/malta-weather.net.png"
import whoiswhois from "./project/whois-whois.net.png"
import memorygame from "./project/memory-game.png"
import myinnerweather from "./project/myinnerweather.png"
import felikitty from "./project/felikitty.png"

// exporting logos permit to have it in the dist directory
export {
  baobaz,
  datasolution,
  cnav,
  malakoff,
  odyssee,
  assurOne,
  mtx,
  oclock,
  opquast,
  iscom,
  stephane,
  maltaweather,
  whoiswhois,
  memorygame,
  myinnerweather,
  felikitty
}
